<template>
  <div
    class="d-flex column-layout"
    :class="{ 'flex-wrap': $vuetify.breakpoint.mdAndDown }"
    style="gap: 24px"
  >
    <!-- Left -->
    <div class="left-wrapper">
      <div
        class="left-column"
        :class="{ 'sticky-top': stickySides || stickyLeft }"
      >
        <slot name="left"></slot>
      </div>
    </div>

    <!-- Middle -->
    <div class="middle-column flex-grow-1" style="max-width: 100%">
      <slot></slot>
    </div>

    <!-- Right -->
    <div v-if="!noRight" class="right-wrapper">
      <div
        class="right-column"
        :class="{ 'sticky-top': stickySides || stickyRight }"
      >
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stickySides: Boolean,
    stickyLeft: Boolean,
    stickyRight: Boolean,
    noRight: Boolean,
  },
};
</script>

<style lang="scss">
.left-wrapper {
  min-width: 250px;
  flex-grow: 1;

  @media screen and (min-width: 960px) {
    max-width: 280px;
  }
}

.right-wrapper {
  min-width: 300px;
  flex-grow: 1;

  @media screen and (min-width: 1264px) {
    max-width: 350px;
  }
}
</style>
